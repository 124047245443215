//Widgets
.swal-button--cancel {
	color: $primary !important;
	background-color: $secondary !important;
	border: 1px solid $secondary !important;
    color: #fff !important;
	&:hover {
		background-color: $primary !important;
		border: 1px solid $primary !important;
		color: #fff !important;
	}
}

.swal-button--confirm {
	color: #fff !important;
	background-color: $secondary !important;
	border: 1px solid $secondary !important;

	&:hover {
		background-color: $primary !important;
		border: 1px solid $primary !important;
		color: #fff !important;
	}
}

.swal-text {
	line-height: 1.8;
	font-size: 1.2rem;
}

.reviewList {
	list-style: none;
	padding-left: 0;

	.title-review {
		display: flex;
		align-items: center;

		>div {
			margin-right: 0.5rem;

			&:last-child {
				margin-right: 0rem;
			}
		}
	}

	.rating {
		display: flex;
		align-items: center;

		span {
			font-size: 1.1rem;
		}
	}
}

.colorOptions {
	>div {
		justify-content: flex-start;

		>div {
			flex-basis: auto;
		}
	}
}

.product-price {
	color: $dark;
	font-size: 1.2rem;
}

.product-sale-price {
	font-size: 1rem;
}

.product-title {
	font-size: 1.1rem;
	font-weight: bold !important;
	display: block;
	cursor: pointer;
}

.filter-widget {
	padding: 1rem 0;
}

.height-item {
	height: 30px
}

.MuiButtonBase-root.blockBtn {
	width: 100%;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	font-size: 18px;
}

.colorOptions {
	margin-bottom: 1.3rem;

	img {
		height: 80px;
		object-fit: cover;
		border: 1px solid $border-color;
	}
}

.sizeGuide {
	margin-bottom: 1.3rem;
}

.MuiPaper-root.product-accordion {
	background: transparent;
	box-shadow: none;

	.MuiExpansionPanelSummary-root {
		padding-left: 0;
		padding-right: 0;

		p {
			font-weight: bold;
		}
	}

	.MuiExpansionPanelDetails-root {
		padding: 0;
	}
}

.ratingHeader {
	margin-bottom: 1rem !important;
	background: $white;
	padding: 2rem 1rem;

	h2 {
		margin-right: 1rem;
	}
}

.rating {
	line-height: 0.4;

	span {
		color: $rating-color;
	}
}

// .white-btn-color .title-content button,
// .white-btn-color .title-content a {
//   color: $white;
//   border-color: $white;
// }
.reviewNum {
	opacity: 0.6;
}

.reviewMainComment {
	font-weight: 500;
	margin-bottom: 0.4rem;
	display: block;
}

.reviewList {
	li {
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		margin-bottom: 1rem;
	}
}

.cart-list-item {
	background: $white;
	padding: 1.5rem;
	margin-bottom: 3rem;
	display: flex;

	.cart-item-thumb {
		width: 160px;
		line-height: 0.9;
	}

	.cart-item-content {
		width: calc(100% - 160px - 150px);
	}

	.cart-item-action {
		width: 150px;
	}

	img {
		height: 200px;
		object-fit: contain;
	}

	.cart-delete {
		padding-left: 0;
		padding-right: 0;
		min-width: auto;
		margin-top: 8px;
	}
}

.cart-wrapper {
	background: $gray-shade-3;
}

.cart-detail {
	h6 {
		margin-bottom: 0.8rem;
	}
}

.cart-detail {
	button {
		padding: 0;
		min-width: auto;
	}

	p {
		margin-bottom: 0.3rem;
	}
}

.cart-action-panel {
	background: $white;
	padding: 2rem !important;
}

p.cart-item-price {
	margin-left: 1rem;
	font-size: 1.1rem;
	color: $dark;
}

.font-lg {
	font-size: 1.25rem !important;
}

.subtotal {
	background: $white;

	p {
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.cart-wrapper {
	box-shadow: $box-shadow;
}

.cart-main {
	padding: 2rem 2rem 3rem;
}

.cart-counter {
	width: 60px;
}

.cart-counter input {
	padding: 5px 14px !important;
}

.border-right {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.login-side {
	h5 {
		margin-bottom: 1rem;
	}
}

.h-input {
	margin-bottom: 2rem !important;

	&:last-child {
		margin-bottom: 0 !important;
	}
}

.login-side,
.guest-side {
	padding: 4rem;
}

.ecommerce-sigin-wrap .white-container {
	box-shadow: $box-shadow;
	border-radius: 4px;
	margin-bottom: 5rem;
}

.checkout-tabs {
	background: $white;
	padding: 1.5rem;
	box-shadow: $box-shadow;
	border-radius: 4px;

	.MuiTabs-flexContainer>button {
		width: 50%;
		text-align: center;
		display: flex;
		max-width: 50%;
	}

	.react-swipeable-view-container {
		>div {
			overflow: hidden !important;
		}
	}

	.MuiTab-wrapper {
		font-size: 1.2rem;
	}
}

.search-bar {
	background: $white;
	font-size: 1.4rem;
	margin-bottom: 2rem;
	padding: 1.5rem 2rem 1.5rem 2rem;
	border-radius: 5px;
	outline: 0 !important;

	input::placeholder {
		opacity: 1;
	}

	.MuiFormControl-root {
		max-width: 100%;
		width: 100%;
	}

	.MuiInput-underline::before {
		border: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled)::before {
		border-bottom: none;
	}
}

.categories-widget {
	span {
		font-size: 1rem;
	}
}

.officer-list {
	padding: 0 1.067rem;

	.icon-panel {
		a {
			display: inline-block;
			height: 2rem;
			line-height: 2rem;
			text-align: center;
			width: 2rem;
			margin: 0;
		}

		.icon {
			opacity: .5;
			height: 16px;
			width: 16px;
			vertical-align: middle;
			margin: 0;
			transition: opacity .25s;
		}
	}

	ul li {
		i {
			color: #6c757d;
			width: 24px;
			line-height: 2rem;
		}

		;

		a {
			width: calc(100% - 45px - 5rem);
			line-height: 2rem;
			margin: 0 0 0 1rem;
		}
	}
}

#menu-sidebar .MuiDrawer-docked {
	height: 100% !important;
}


.table-title {
	font-weight: 600;
}
.color-primary{
	color: $primary;
}
/* Custom scrollbar styles */
.custom-responsive-wrapper {
	overflow-x: auto !important; /* Enable horizontal scrolling */
	overflow-y: hidden; /* Disable vertical scrolling */
	scrollbar-width: thin; /* Adjust the width of the scrollbar */
	scrollbar-color: gray #F5F5F5; /* Specify the color of the scrollbar */
	cursor: pointer !important;
  
}
  
  .MUIDataTable-responsiveBase-96::-webkit-scrollbar {
	cursor: pointer !important;
	height: 10px !important; /* Adjust the width of the scrollbar */
  }
  
  .MUIDataTable-responsiveBase-96::-webkit-scrollbar-thumb {
	background-color: gray; /* Specify the color of the scrollbar thumb */
	border-radius: 5px; /* Adjust the radius of the scrollbar thumb */
	cursor: pointer !important;

  }
  
  .MUIDataTable-responsiveBase-96::-webkit-scrollbar-track {
	cursor: pointer !important;

	background-color: #F5F5F5; /* Specify the color of the scrollbar track */
  }
  
.d-flex-center{display: flex;
align-items: center;}
.customer-table table tr:hover{
	background-color: #00AD62 !important;
	
}
.customer-table table tbody:hover tr:hover div,
.customer-table table tbody:hover tr:hover p,
.customer-table table tbody:hover tr:hover h1,
.customer-table table tbody:hover tr:hover h2,
.customer-table table tbody:hover tr:hover h3,
.customer-table table tbody:hover tr:hover h4,
.customer-table table tbody:hover tr:hover li,
.customer-table table tbody:hover tr:hover span,
.customer-table table tbody:hover tr:hover h5 {
  background-color: transparent !important;
  color: #fff !important;
}
.customer-table table tr:hover td:not(.customer-table table tr:hover.MuiTableRow-footer td){
	color: #fff !important ;
}
.customer-table table tr:hover.MuiTableRow-footer{
	background-color: transparent !important;

}
.customer-table table tr .material-icons{
	color: #00AD62 !important;
}
.button-update{
	padding: 14px 8px !important;
	width: 200px !important;
	border-radius: inherit !important;
}
.background-tab{margin-top:0px !important;
    background: #00356708;
    padding: 30px 15px;}
.customer-table table tr:hover td a{
	color: #fff !important;
}
.customer-table table tr:hover td span :not(.customer-table table tr:hover.MuiTableRow-footer td span){
	color: #fff !important;
}
.mr-0{
	margin-right: 0px !important;
}
.title-banner {
	padding: 2em 0 !important;
	background: $primary !important;
	color: $white;
	padding-bottom: 1rem;
	box-shadow: $box-shadow;

	.title-content {

		h2,
		h4 {
			color: $white;
		}
	}
}

.search-bar {
	margin-bottom: 2rem;
	box-shadow: $box-shadow;
}

.counter {
	width: 100px;
}

.ribbon {
	width: 130px;
	height: 130px;
	overflow: hidden;
	position: absolute;
}

.ribbon::before,
.ribbon::after {
	position: absolute;
	z-index: -1;
	content: "";
	display: block;
}

.ribbon span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 3px 0;
	background-color: $primary;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: $white;
	font-size: 12px;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;
}

/* top right*/
.ribbon-top-right {
	top: -10px;
	right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
	border-top-color: transparent;
	border-right-color: transparent;
}

.ribbon-top-right::before {
	top: 0;
	left: 0;
}

.ribbon-top-right::after {
	bottom: 0;
	right: 0;
}

.ribbon-top-right span {
	left: -24px;
	top: 30px;
	transform: rotate(45deg);
}

.product-list {
	box-shadow: $box-shadow;
}

.product-list-layout {
	.product-list {
		width: 100%;

		img {
			width: 100%;
		}
	}
}

.product-wishlist {
	position: absolute !important;
	right: 0px;
	top: 10px;
	opacity: 0;
	transform: translateX(0px);
	transition: all 0.2s ease-in !important;
}

.product-list:hover .product-wishlist {
	transform: translateX(-10px);
	opacity: 1;
}

.mb-2>* {
	margin-bottom: 15px;
}

.text-right {
	text-align: right;
}

.main-invoice {
	/* border: 2px solid $border-color; */
	padding: 2rem;
}
.box-color .welcome-note{
	background: #00356708 ;
    border-radius: initial ;
	.MuiTableRow-head{
	background: #00356708!important;
	box-shadow: none !important;

	}
}

.title-invoice{
	font-size: 18px;
    font-weight: 700;
    color: #00AD62;
}
.green-title{
	color: green;
	font-weight: 500;
}
.invoice-table{
	background-color: transparent !important;
	box-shadow: none !important;
}
.invoice-table .MuiTableRow-head th{
	color: #48535e !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
}
.border-td {
	background-color: #00AD621f !important;
	border: 2px solid #00AD62 !important;
  }
  .vignette-validity-until{
	display: flex;
    align-items: center;
    justify-content: center;
  }
.th-order-title{
	color: #00AD62 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
@media (min-width: 576px)
{.height-valid {
    height: 55px!important;
}}
.prefix-label, .separator-label, .to-time-label {
	margin-right: 2px;
	margin-left: 2px;
    font-size: 1.15rem;
    font-weight: 600;
    line-height: 1.5;
}
.form-label, .to-date-label ,.begin-label{
	margin-right: 2px;
	margin-left: 2px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
}
.orange-title{
	color: orange;
	font-weight: 500;

}
.bg-color.box-color .welcome-note{
	background-color: #fff !important;
}
.box-color .welcome-note h6{
	color: #48535e !important;
    font-style: normal !important ;
    font-weight: 700 !important;
    font-size: 18px !important;
}
.border-highlight {
	background: $gray-shade-3;
	color: $dark !important;
	padding: 10px;
	border-radius: 3px;
}

.invoice-page {
	.MuiTableCell-head {
		color: $dark !important;
		background: $gray-shade-3;
		font-weight: 500;
	}
}

.sideline {
	height: auto;
}

.update-img-thumb {
	position: absolute;
	right: 60px;
	top: 120px;
}

.img-holder {
	background: $img-holder-color;
	line-height: 0.7;
}

.lg-label span {
	font-size: 0.9rem;
}

@media (max-width: 1199px) {

	.login-side,
	.guest-side {
		padding: 1.5rem;
	}

	.cart-main {
		padding: 1rem;
	}
}

@media (max-width: 959px) {
	.shop-listing .shop-sidebar {
		box-shadow: $box-shadow;
	}

	.shop-list-btm {
		flex-direction: inherit !important;
		justify-content: space-between !important;
		margin-bottom: 0;
		align-items: center !important;
	}

	.cart-list-item {
		padding: 1rem;
		margin-bottom: 2rem;
	}
}

@media (max-width: 599px) {
	.cart-main {
		padding: 0.5rem;
	}

	.cart-list-item {
		display: block;

		.cart-item-thumb,
		.cart-item-content,
		.cart-item-action {
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.cart-item-action {
		>div {
			justify-content: space-between !important;
		}
	}

	.main-invoice {
		.text-right {
			text-align: left;
		}

		padding: 1rem;
	}

	.shop-wrapper {
		.to-top {
			margin-top: -2.5rem;
		}

		.search-bar {
			padding: 1.25rem 1rem;
		}
	}

	.hulk-scroll.main-content {
		height: calc(100vh - 56px) !important;
	}

	.ecommerce-sigin-wrap .to-top {
		margin-top: 0;
		padding-top: 5rem;
	}
}
.thm-btn {
	cursor: pointer;
    padding: 11px 28px!important;
	border-radius: initial !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    border: 2px solid #00AD62;
}
.thm-btn:hover {  
    border: 1px solid#121230 ;
}
.red-color{
	color: red !important;
}
.company-title{
	margin-top: 16px;
    font-size: 18px !important;
    color: #00AD62;
    font-weight: 500;
}
.font-title-customer{
	font-size: 14px !important;
	
}
.box-shadow{
	box-shadow: none !important;
}
.color-toll{
    color: #00AD62 !important;

}
.counter {
    width: 300px;
}
.min-height-btn{
	width: 100%;
	min-height: 62px;
	display: flex;
    align-items: center;
    justify-content: center;
}
//welcome note
.quicklink .welcome-note {
	border-top: 4px solid $primary
}


.welcome-note {
	p {
		font-size: 1rem !important;
	}

	.welcome-note-list {
		display: -webkit-box;
		display: flex;
		padding: .5rem 0;
		-webkit-box-align: center;
		align-items: center;

		i {
			font-size: 1.875rem;
			width: 55px;
			height: 55px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;

			margin-right: 1rem;
		}

		p {
			margin-bottom: 0;

			span {
				display: block;
				line-height: 1.2;
				font-size: 1.1rem !important;
			}
		}
	}
}

.welcome-note-list.filter-table {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;

	i {
		font-size: 1.3rem;
		width: 25px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;

		margin-right: .3rem;
	}

	h5 {
		font-size: 1.3rem;

	}

	p {
		margin-bottom: 0;

		span {
			display: block;
			line-height: 1.2;
			font-size: 1.1rem !important;
		}
	}
}

.box-filter {
	box-shadow: none !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.searchInput input {
	margin-left: 40px;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
	border-top: 5px solid $secondary !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
	background-color: hsl(0, 0%, 100%) !important;
	border-color: hsl(0, 0%, 80%) !important;
}

.sticky-side {
	max-height: 80vh;
	overflow-x: hidden;
	overflow-y: auto;
	position: -webkit-sticky;
	position: sticky;
	top: 2rem;
	z-index: 1;
}

.accordionIcon {
	vertical-align: middle;
	font-size: 1.5rem;
	width: 21px;
	height: 21px;
}

.hl-text {
	font-weight: bold !important;
}

.badge-primary {
	background-color: rgba($primary, 0.15);
	color: $primary;
}

.badge-success {
	background-color: rgba($success, 0.15);
	color: $success;
}

.badge-warning {
	background-color: rgba($secondary, 0.15);
	color: $secondary;
}

.badge-danger {
	background-color: rgba($error, 0.15);
	color: $error;
}

.quick-links {
	padding-left: 0;
	padding-top: 10px;
	margin: 0.4rem 0 0 0;
}

.quick-links li {
	list-style: none;
	display: -webkit-inline-box;
	display: inline-flex;
	width: 50%;
	-webkit-box-align: center;
	align-items: center;
	margin: 0.5rem 0;

	&:nth-child(even) {
		padding-left: 12px;
	}

	a {
		display: flex;
		align-items: center;
		color: $dark;

		i {
			margin-right: 0.5rem;
			font-size: 20px;
		}

		span {
			margin-right: 0.5rem;
			//color: $dark;
		}

		&:hover {
			color: $secondary;
		}
	}
}

.welcome-title {
	font-size: 1rem;
	font-weight: bold;
}

.view-subsec {
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		display: flex;
		align-items: center;
		margin-right: 5px;
	}
}

.p-0 {
	padding: 0 !important;
}

.titleOfficer {
	color: $primary !important;
	padding: 0 16px !important;

	:hover {
		color: $secondary !important
	}
}

.plain-block {
	h2 {
		font-size: 2.8rem;
	}

	h2,
	h5 {
		font-weight: normal !important;
	}
}

.dot-circle {
	width: 16px;
	height: 16px;
	display: -webkit-inline-box;
	display: inline-flex;
	border-radius: 16px;
	margin-right: 5px;
}

.absolute-center {
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-table {
	border-top: 2px solid $primary;

	th {
		background-color: #fafafa;
		border-left-color: #fafafa;
		border-right-color: #fafafa;
	}



	.title {
		color: $primary;
		text-transform: uppercase;
		text-decoration: underline;
		font-weight: 600;
	}

	tr:nth-child(even) {
		background-color: #fafafa;
		border-left-color: #fafafa;
		border-right-color: #fafafa;
	}

	tr:nth-child(odd) {
		background-color: #FFF;
		border-left-color: transparent;
		border-right-color: transparent;
	}

	.footer-tr {
		cursor: pointer;
		color: $primary;
		font-weight: 600;

		&:hover {
			background-color: #fafafa;
		}


	}

}
.search-user-input .MuiInputBase-input{
	border: none !important;
 }
 .customer-details-table .MuiInputBase-input{
	border: none !important;
 }
 .date-start-end input{
	margin: 10px 0px !important;
	width: 300px !important;
	padding: 10px !important;
	font-weight: 600 !important;
	border: 3px solid #00AD62 !important;
 }
 .date-start-end  .MuiInput-underline::before{

	content: none !important;
 }
 .date-start-end  .MuiInput-underline::after{

	content: none !important;
 }