// Custom Variable

// Common color
$white:#ffffff;
$bg-default:#fafafa;
$gray-shade-1:#f4f5f9;
$gray-shade-2:#f5f5f5;
$gray-shade-3:#f3f3f3;

//Primary theme
$primary:#00AD62;
$secondary: #00AD62;
$success: #00d41a;
$info: #00d0bd;
$error: #e53935;
$warning: #ffb70f;

// Text color
$text-color: #121230;
$text-sec-color:#717275;
$dark: #121230;
$color-disabled:#969fa4;

// Border color
$border-color: #e0e0e0;

// Rating color
$rating-color:#f9bf00;

//Shop List Img Bg Color
$img-holder-color:#edf1f2;
$facebook: #3c5a9a;
$google: #db4437;
$twitter: #1da1f2;
$github: #000000;

// Box Shadow
$box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);